<!--
 * @Descripttion: 工具下载
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-26 08:41:16
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-27 16:22:21
-->
<template>
    <div class="designAssistant">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">工具下载</div>
        </div>
        <div class="case-wrap">
            <div class="cw-top">
                <div class="top cwt-left">
                    <div class="title">全景铺贴作图工具</div>
                    <div class="tag-wrap">
                        <span>简单快捷的设计全景效果图，自由线上铺贴换砖体验连纹背景墙都可展示效果</span>
                        <span>手机、pad、电脑多平台都可操作，高清2k显示；外出谈单更方便快捷</span>
                    </div>
                    <div class="btn-upload">
                        <a class="module" href="https://taocitong.oss-cn-beijing.aliyuncs.com/setup/zhudiantong_sheji_Setup4.exe" target="_self" >
                            <div class="btn">下载工具</div>
                        </a>
                    </div>
                </div>
                <div class="top cwt-right">
                    <img class="pic" src="../../assets/1.png" mode="" />
                </div>
            </div>
            <div class="cw-bottom">
                <div class="bottom cwb-left">
                    <div class="title">全景设计</div>
                    <div class="content">
                        我们可以实时并且简单快捷地设计客户所需的产品全景效果图，使客户获得更好的
                        体验。更强大的是全景设计工具与我们的线上店铺紧密相连，每个设计好的产品全
                        景效果图都可以导入呈现在店铺的产品详情页
                    </div>
                    <img class="pic" src="../../assets/2.png" mode="" />
                </div>
                <div class="bottom cwb-right">
                    <div class="title">设计助手</div>
                    <div class="content">
                        可将品牌下发活动物料电子文件，简单快捷地结合需求进行内容修改
                    </div>
                    <img class="pic" src="../../assets/1.png" mode="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
export default {
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index: 'designAssistant',
        }
    },
    mounted () {

    },
    methods: {

    },
}
</script>

<style lang='scss'>
.designAssistant{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 40px;
        margin: 20px 0;
        border-radius: 6px;
        .cw-top{
            display: flex;
            justify-content: space-between;
            border-bottom: 3px solid #eee;
            padding-bottom: 40px;
            .top{
                flex: 1;
            }
            .cwt-left{
                .title{
                    font-size: 32px;
                    color: #000;
                }
                .tag-wrap{
                    display: flex;
                    flex-direction: column;
                    line-height: 50px;
                    font-size: 16px;
                    color: #999;
                    margin-top: 50px;
                }
                
                .btn-upload{
                    cursor: pointer;
                    width: 200px;
                    height: 60px;
                    line-height: 60px;
                    background: #0a1529;
                    border-radius: 60px;
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                    margin-top: 110px;
                    .module{
                        width: 100%;
                        height: 100%;
                        color: #fff;
                        text-decoration:none;
                        .btn{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
            .cwt-right{
                padding-left: 30px;
                .pic{
                    width: 100%;
                }
            }
        }
        .cw-bottom{
            display: flex;
            justify-content: space-between;
            .bottom{
                flex: 1;
                padding-top: 40px;
            }
            .cwb-left{
                padding-right: 60px;
                .title{
                    font-size: 32px;
                    color: #000;
                }
                .content{
                    margin: 20px 0;
                    line-height: 40px;
                    font-size: 16px;
                    color: #999;
                }
                .pic{
                    width: 100%;
                }
            }
            .cwb-right{
                padding-left: 60px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .title{
                    font-size: 32px;
                    color: #000;
                }
                .content{
                    margin: 20px 0;
                    line-height: 40px;
                    font-size: 16px;
                    color: #999;
                    margin-bottom: 100px;
                }
                .pic{
                    width: 100%;
                }
            }
        }
    }
}
</style>
